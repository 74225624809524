<template>
    <vs-popup class="memberStatus_popup" title="Etat change" :active.sync="popupActive">
        
        <template v-if="uid_list.length==0">
            <p>Aucun membre choisie !</p>
        </template>
        <template v-else>
            <div class="w-3/3 px-5">
                <h3>Changement etat <template v-if="uid_list.length>1">de {{ uid_list.length }} utilisateurs</template></h3><br/>
                <vs-select v-model="status" class="w-full select-large">
                    <vs-select-item key="1" value="" text="" class="w-full" />
                    <vs-select-item key="2" value="1" text="Actif" class="w-full" />
                    <vs-select-item key="3" value="0" text="Inactif" class="w-full" />
                    <vs-select-item key="4" value="-1" text="Suspendu" class="w-full" />
                </vs-select>
            </div>
            <vs-divider/>
            <vs-row>
                <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                    <div></div>
                    <vs-button color="success" type="filled" v-on:click="upd()">Modifier</vs-button>
                </vs-col>
            </vs-row>
        </template>
    </vs-popup>
</template>

<style lang="scss">
.memberStatus_popup
{

}
</style>

<script>
import vSelect from 'vue-select'
import Member  from '@/database/models/member'

export default {
    components:{
        vSelect,
	},
    props: {
    },
    data(){
        return{
            popupActive:false,
            callback:null,

            uid_list : [],
            status   : '',
        }
    },
    watch:{
        $route (){
        },
    },
    beforeMount:function(){

    },
    methods:{
        openPopup( uid_list, callback )
        {
            this.uid_list    = uid_list
            this.status      = ''
            this.callback    = null
            this.popupActive = true

            if(callback)
                this.callback = callback
        },
        upd()
        {
            let compte = 0;

            let end = ()=>{
                compte++

                if(compte >= this.uid_list.length )
                if(this.callback)
                    this.callback()
            }

            //update member
            if(this.status != '')
            for( var i=0; i<this.uid_list.length; i++)
            {
                Member.update( this.uid_list[i].uid, { active:this.status })
                .then(end)
                .catch((err)=>{
                    alert(err)
                    end()
                })
            }

            //fermeture popup
            this.popupActive = false
        },
    }
}

</script>